// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "lucide-static/font/lucide.css"
import "../scss/main.scss"
import "./Funneldraw"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

document.addEventListener('DOMContentLoaded', function() {
  const shareLinkActions = document.querySelectorAll('.share-link-action');
  
  shareLinkActions.forEach(function(link) {
    link.addEventListener('click', function() {
      const modalId = link.getAttribute('data-modalid');
      
      const modalElement = document.getElementById(modalId);
      
      if (modalElement) {
        modalElement.classList.toggle('active');
      }
    });
  });
});

document.addEventListener('DOMContentLoaded', function() {
  const copyLinkButtons = document.querySelectorAll('.copy-link');

  copyLinkButtons.forEach(function(button) {
    button.addEventListener('click', function(e) {
      e.preventDefault();
      const linkToCopy = button.getAttribute('data-link');

      if (linkToCopy) {
        navigator.clipboard.writeText(linkToCopy).then(function() {
          button.classList.add('active');
          console.log('Link copiado para a área de transferência!');
        }).catch(function(error) {
          console.error('Erro ao copiar o link: ', error);
        });
      } else {
        console.error('Atributo "data-link" não encontrado.');
      }
    });
  });
});
