import React, { memo } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import 'react-edit-text/dist/index.css';
import { useModalNode } from './atoms/modalNode/useModalNode';

export default memo(({ data, isConnectable, id, ...props}) => {
  const [modalNode, setModalNode] = useModalNode();
  const hook = useReactFlow();

  console.log('hook', hook.getNodes()[0].nodesDraggable);

  return (
    <div className="element__node">
      { !data.social && (
        <Handle
          type="target"
          position={Position.Left}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={isConnectable}
        />
      )}
      <div 
        className="element-landingpage"
        style={{
          backgroundImage: `url(${data.image.url})`,
          height: data.image.height,
          width: data.image.width
        }}
      >
        <button 
          className="element__edit"
          onClick={() => setModalNode({
            isModalOpen: true,
            id: id,
            label: data.label,
            link: data.link
          })}
        >
          Editar
          <i className="icon-pencil"></i>
        </button>
      </div>
      { data.label && (
        <>
          { data.link 
            ?
              <a 
                target="_blank" 
                href={data.link}
                className="element__title"
              >
                {data.label}

                <i className="element__icon icon-external-link"></i>
              </a>
            :
              <span 
                className="element__title"
              >
                {data.label}
              </span>
          }
        </>
      )}

      <Handle
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable}
      />
    </div>
  );
});
