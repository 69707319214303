// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useEffect, useCallback, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  Controls,
  Background
} from 'reactflow'
import 'reactflow/dist/style.css'

import './Funneldraw.scss'

import ImageNode from './ImageNode'

import landingPage from './landing-page.svg';
import showroom from './showroom.svg';
import obrigado from './obrigado.svg';
import upsell from './upsell.svg';
import orderBump from './order-bump.svg';

import facebook from './facebook.svg';
import instagram from './instagram.svg';
import googleAds from './google-ads.svg';
import radio from './radio.svg';
import tv from './tv.svg';

import comprar from './comprar.svg';

import Sidebar from './Sidebar';

import { useModalNode } from './atoms/modalNode/useModalNode';

const connectionLineStyle = { stroke: "#fff" };
const snapGrid = [20, 20];
const nodeTypes = {
  imageNode: ImageNode
};

const defaultViewport = { x: 0, y: 0, zoom: 0.5 };

const getId = () => `${uuidv4()}`;

const Funneldraw = props => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onChange = (value, id) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id !== id) {
          return node;
        }

        return {
          ...node,
          data: {
            ...node.data,
            label: value,
          },
        };
      }),
    );
  };

  useEffect(() => {
    const nodesWithOnChange = props.nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          onChange: onChange
        },
      };
    })

    setNodes(nodesWithOnChange);
    setEdges(props.edges);
  }, []);

  useEffect(() => {
    document.getElementById('funnel-nodes').value = JSON.stringify(nodes);
    document.getElementById('funnel-edges').value = JSON.stringify(edges);
  }, [nodes, edges]);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({
      ...params, 
      animated: true, 
      style: { stroke: "#000" }
    }, eds)),
    [],
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData('application/reactflow');
      const dataNode = JSON.parse(event.dataTransfer.getData('application/reactflow-data'));

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: {
          ...dataNode,
          onChange: onChange
        },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance],
  );

  const [modalNode, setModalNode] = useModalNode();
  const closeModal = () => setModalNode({
    isModalOpen: false,
    id: '',
    label: '',
    link: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    setNodes((nds) =>
      nds.map((node) => {
        if (node.id !== modalNode.id) {
          return node;
        }

        return {
          ...node,
          data: {
            ...node.data,
            label: modalNode.label,
            link: modalNode.link
          },
        };
      }),
    );

    closeModal();
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && modalNode.isModalOpen) {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [modalNode.isModalOpen]);

  return (
    <div>
      <div className="dndflow">
        <ReactFlowProvider>
          { props.editable &&
            <Sidebar onChange={onChange} />
          }
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              nodeTypes={nodeTypes}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              snapToGrid={true}
              snapGrid={snapGrid}
              defaultViewport={defaultViewport}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              fitView
              attributionPosition="bottom-left"
              nodesDraggable={props.editable}
              nodesConnectable={props.editable}
            >
              <Controls />
              <Background />

              { modalNode.isModalOpen && 
                <div className="overlay" onClick={() => closeModal()}>
                  <div className="modal" onClick={(e) => e.stopPropagation()}>
                      <button className="close-button float-right" onClick={() => closeModal()}>
                        <i className="icon-x"></i>
                      </button>
                      <h2 className="modal__title">Editar elemento</h2>
                      <hr className="modal__separator"></hr>
                      <form onSubmit={handleSubmit}>
                          <div className="modal__form-group">
                            <label className="modal__label" htmlFor="label">Título:</label>
                            <input
                              type="text"
                              id="label"
                              name="label"
                              value={modalNode.label}
                              onChange={(e) => setModalNode({
                                ...modalNode,
                                label: e.target.value
                              })}
                              required
                            />
                          </div>
                          <div className="modal__form-group">
                            <label className="modal__label" htmlFor="link">Link:</label>
                            <input
                              type="url"
                              id="link"
                              name="link"
                              value={modalNode.link}
                              onChange={(e) => setModalNode({
                                ...modalNode,
                                link: e.target.value
                              })}
                            />
                          </div>
                          <button type="submit" className="button float-right">Editar</button>
                      </form>
                  </div>
                </div>
              }
            </ReactFlow>
          </div>
        </ReactFlowProvider>
      </div>
    </div>
  )
}

Funneldraw.defaultProps = {
  name: 'Funnel',
  nodes: [],
  edges: []
}

Funneldraw.propTypes = {
  name: PropTypes.string,
  nodes: PropTypes.array,
  edges: PropTypes.array
}

document.addEventListener('DOMContentLoaded', () => {
  const elFunnelDraw = document.getElementById("funneldraw");

  if (elFunnelDraw) {
    let nodes = document.getElementById('funnel-nodes').value.trim();
    let edges = document.getElementById('funnel-edges').value.trim();
    let editable = document.getElementById('funneldraw').dataset.editable;

    if (nodes === '') {
      nodes = []
    } else {
      nodes = JSON.parse(nodes)
    }

    if (edges === '') {
      edges = []
    } else {
      edges = JSON.parse(edges)
    }

    if (editable) {
      editable = JSON.parse(editable)
    } else {
      editable = false
    }

    ReactDOM.render(
      <Funneldraw name="Funnel" nodes={nodes} edges={edges} editable={editable}/>,
      elFunnelDraw,
    )
  }
})
