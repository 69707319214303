import React, { useState } from 'react';

import './Sidebar.scss'

export default () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeSidebar, setActiveSidebar] = useState(true);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleSidebarClick = (active) => {
    setActiveSidebar(active);
  };

  const onDragStart = (event, nodeType, data) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('application/reactflow-data', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <aside>
      <div className="sidebar-menu">
        <button 
          className="sidebar-menu__icon icon-plus"
          onClick={() => handleSidebarClick(true)}
        ></button>
      </div>
      <div className={`sidebar-elements ${activeSidebar ? 'active' : ''}`}>
        <header className="sidebar-elements__header">
          <i className="sidebar-elements__icon icon-waypoints"></i>
          Elementos

          <i 
            className="sidebar-elements__back icon-chevrons-left"
            onClick={() => handleSidebarClick(false)}
          ></i>
        </header>
        <ul className="sidebar-items">
          <li 
            className={activeTab === 0 ? 'active' : ''}
            onClick={() => handleTabClick(0)}
          >
            Páginas
          </li>
          <li 
            className={activeTab === 1 ? 'active' : ''}
            onClick={() => handleTabClick(1)}
          >
            Online
          </li>
          <li 
            className={activeTab === 2 ? 'active' : ''}
            onClick={() => handleTabClick(2)}
          >
            Offline
          </li>
          <li 
            className={activeTab === 3 ? 'active' : ''}
            onClick={() => handleTabClick(3)}
          >
            Ações
          </li>
        </ul>
        <div className="sidebar-elements__content">
          {/* PAGINAS */}
          <div className={`sidebar-elements__content-2 ${activeTab === 0 ? 'active' : ''}`}>
            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'150px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/showroom.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                    image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/showroom.svg'
                    },
                    social: false,
                    label: "Showroom"
                })} draggable
              />
              <span className="element__title">Showroom</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'150px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/landing-page.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                    image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/landing-page.svg'
                    },
                    social: false,
                    label: "Landing Page"
                })} draggable
              />
              <span className="element__title">Landing Page</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'150px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/pagina-venda.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                    image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/pagina-venda.svg'
                    },
                    social: false,
                    label: "Página de Vendas"
                })} draggable
              />
              <span className="element__title">Página de Vendas</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'150px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/diversa.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                    image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/diversa.svg'
                    },
                    social: false,
                    label: "Diversa"
                })} draggable
              />
              <span className="element__title">Diversa</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'150px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/order-bump.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                    image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/order-bump.svg'
                    },
                    social: false,
                    label: "Order Bump"
                })} draggable
              />
              <span className="element__title">Order Bump</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'150px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/upsell.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                    image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/upsell.svg'
                    },
                    social: false,
                    label: "Upsell"
                })} draggable
              />
              <span className="element__title">Upsell</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'150px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/downsell.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                    image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/downsell.svg'
                    },
                    social: false,
                    label: "Downsell"
                })} draggable
              />
              <span className="element__title">Downsell</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'150px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/obrigado.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                    image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/obrigado.svg'
                    },
                    social: false,
                    label: "Obrigado"
                })} draggable
              />
              <span className="element__title">Obrigado</span>
            </div>
            
            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'150px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/blogpost.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/blogpost.svg'
                  },
                  social: false,
                  label: "Blogpost"
                })} draggable
              />
              <span className="element__title">Blogpost</span>
            </div>
            
            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'150px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/calendario.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                    image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/calendario.svg'
                    },
                    social: false,
                    label: "Calendário"
                })} draggable
              />
              <span className="element__title">Calendário</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'150px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/webinar.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                    image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/paginas/webinar.svg'
                    },
                    social: false,
                    label: "Webinar"
                })} draggable
              />
              <span className="element__title">Webinar</span>
            </div>
          </div>

          {/* ONLINE */}
          <div className={`sidebar-elements__content-3 ${activeTab === 1 ? 'active' : ''}`}>
            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/facebook.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/facebook.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Facebook"
                })} draggable
              />
              <span className="element__title">Facebook</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/intagram.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/intagram.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Instagram"
                })} draggable
              />
              <span className="element__title">Instagram</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/tiktok.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/tiktok.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Tiktok"
                })} draggable
              />
              <span className="element__title">Tiktok</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/google-ads.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/google-ads.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Google Ads"
                })} draggable
              />
              <span className="element__title">Google Ads</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/youtube.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/youtube.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Youtube"
                })} draggable
              />
              <span className="element__title">Youtube</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/email.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/email.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "E-mail"
                })} draggable
              />
              <span className="element__title">E-mail</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/whatsapp.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/whatsapp.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Whatsapp"
                })} draggable
              />
              <span className="element__title">Whatsapp</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/telegram.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/telegram.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Telegram"
                })} draggable
              />
              <span className="element__title">Telegram</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/chat-bot.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/chat-bot.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Chat Bot"
                })} draggable
              />
              <span className="element__title">Chat Bot</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/crm.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/crm.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "CRM"
                })} draggable
              />
              <span className="element__title">CRM</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/dms.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/dms.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "DMS"
                })} draggable
              />
              <span className="element__title">DMS</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/icarros.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/icarros.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "iCarros"
                })} draggable
              />
              <span className="element__title">iCarros</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/integrador.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/integrador.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Integrador"
                })} draggable
              />
              <span className="element__title">Integrador</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/linkedin.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/linkedin.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Linkedin"
                })} draggable
              />
              <span className="element__title">Linkedin</span>
            </div>
            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/marketplace.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/marketplace.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Marketplace"
                })} draggable
              />
              <span className="element__title">Marketplace</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/montadora.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/montadora.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Montadora"
                })} draggable
              />
              <span className="element__title">Montadora</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/olx.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/olx.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "OLX"
                })} draggable
              />
              <span className="element__title">OLX</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/popup.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/popup.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Popup"
                })} draggable
              />
              <span className="element__title">Popup</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/spotify.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/spotify.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Spotify"
                })} draggable
              />
              <span className="element__title">Spotify</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/waze.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/waze.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Waze"
                })} draggable
              />
              <span className="element__title">Waze</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/webmotors.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/webmotors.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Webmotors"
                })} draggable
              />
              <span className="element__title">Webmotors</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/mercado-livre.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/mercado-livre.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Mercado Livre"
                })} draggable
              />
              <span className="element__title">Mercado Livre</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/google-maps.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/online/google-maps.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Google Maps"
                })} draggable
              />
              <span className="element__title">Google Maps</span>
            </div>
          </div>

          {/* OFFLINE */}
          <div className={`sidebar-elements__content-3 ${activeTab === 2 ? 'active' : ''}`}>
            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/delivery.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/delivery.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Delivery"
                })} draggable
              />
              <span className="element__title">Delivery</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/despachante.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/despachante.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Despachante"
                })} draggable
              />
              <span className="element__title">Despachante</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/emplacamento.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/emplacamento.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Emplacamento"
                })} draggable
              />
              <span className="element__title">Emplacamento</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/evento.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/evento.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Evento"
                })} draggable
              />
              <span className="element__title">Evento</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/jornal.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/jornal.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Jornal"
                })} draggable
              />
              <span className="element__title">Jornal</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/ligacao.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/ligacao.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Ligação"
                })} draggable
              />
              <span className="element__title">Ligação</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/outdoor.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/outdoor.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Outdoor"
                })} draggable
              />
              <span className="element__title">Outdoor</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/qrcode.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/qrcode.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "QRcode"
                })} draggable
              />
              <span className="element__title">QRcode</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/radio.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/radio.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Rádio"
                })} draggable
              />
              <span className="element__title">Rádio</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/reuniao.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/reuniao.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Reunião"
                })} draggable
              />
              <span className="element__title">Reunião</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/revista.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/revista.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Revista"
                })} draggable
              />
              <span className="element__title">Revista</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/sms.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/sms.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "SMS"
                })} draggable
              />
              <span className="element__title">SMS</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/test-drive.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/test-drive.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Test Drive"
                })} draggable
              />
              <span className="element__title">Test Drive</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/tv.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/tv.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "TV"
                })} draggable
              />
              <span className="element__title">TV</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/visita.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/visita.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Visita"
                })} draggable
              />
              <span className="element__title">Visita</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/encantador-leads.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/encantador-leads.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Encantador de Leads"
                })} draggable
              />
              <span className="element__title">Encantador de Leads</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'64px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/executivo-vendas.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/offline/executivo-vendas.svg',
                    height: '64px',
                    width: '64px'
                  },
                  social: false,
                  label: "Executivo de Vendas"
                })} draggable
              />
              <span className="element__title">Executivo de Vendas</span>
            </div>
          </div>

          {/* ACOES */}
          <div className={`sidebar-elements__content-3 ${activeTab === 3 ? 'active' : ''}`}>
            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/adicionar-tag.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/adicionar-tag.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Tag"
                })} draggable
              />
              <span className="element__title">Tag</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/agendar.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/agendar.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Agendar"
                })} draggable
              />
              <span className="element__title">Agendar</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/assistir-video.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/assistir-video.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Vídeo"
                })} draggable
              />
              <span className="element__title">Vídeo</span>
            </div>
            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/avaliar-usado.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/avaliar-usado.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Avaliação"
                })} draggable
              />
              <span className="element__title">Avaliação</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/clicar.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/clicar.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Clicar"
                })} draggable
              />
              <span className="element__title">Clicar</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/comprar.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/comprar.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Comprar"
                })} draggable
              />
              <span className="element__title">Comprar</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/condicional.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/condicional.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Condição"
                })} draggable
              />
              <span className="element__title">Condição</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/contactar.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/contactar.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Contactar"
                })} draggable
              />
              <span className="element__title">Contactar</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/disparar-cadencia.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/disparar-cadencia.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Cadência"
                })} draggable
              />
              <span className="element__title">Cadência</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/download.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/download.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Download"
                })} draggable
              />
              <span className="element__title">Download</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/lead.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/lead.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Lead"
                })} draggable
              />
              <span className="element__title">Lead</span>
            </div>
            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/onar-carrinho.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/onar-carrinho.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Carrinho"
                })} draggable
              />
              <span className="element__title">Carrinho</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/qualificar.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/qualificar.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Qualificar"
                })} draggable
              />
              <span className="element__title">Qualificar</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/remarketing.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/remarketing.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Remarketing"
                })} draggable
              />
              <span className="element__title">Remarketing</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/reservar.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/reservar.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Reservar"
                })} draggable
              />
              <span className="element__title">Reservar</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/scroll.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/scroll.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Scroll"
                })} draggable
              />
              <span className="element__title">Scroll</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/simular-financiamento.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/simular-financiamento.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Financiamento"
                })} draggable
              />
              <span className="element__title">Financiamento</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/vender.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/vender.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Vender"
                })} draggable
              />
              <span className="element__title">Vender</span>
            </div>

            <div className="sidebar-elements__item">
              <img 
                style={{ maxWidth:'65px' }}
                src='https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/usado-troca.svg'
                onDragStart={(event) => onDragStart(event, 'imageNode', {
                  image: {
                    url: 'https://ws-dummy-images.s3.amazonaws.com/funneldraw/acoes/usado-troca.svg',
                    width: '65px',
                    height: '81px'
                  },
                  social: false,
                  label: "Usado na Troca"
                })} draggable
              />
              <span className="element__title">Usado na Troca</span>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};
